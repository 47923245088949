define("accio-alert/services/realtime", ["exports", "accio-alert/config/environment", "ember-phoenix/services/phoenix-socket"], function (_exports, _environment, _phoenixSocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _phoenixSocket.default.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    connect: function connect() {
      if (this.get('ajax.headers.Authorization')) {
        this._super(_environment.default.socketURL, {
          params: {
            guardian_token: this.get('ajax.headers.Authorization').replace('Bearer ', '')
          }
        });
      }
    },
    namespace: Ember.computed('currentUser.user.isPublicUser', function () {
      return this.get('currentUser.user.isPublicUser') ? 'public_users' : 'internal_users';
    }),
    autoJoinChannels: function autoJoinChannels() {
      if (!this.get('session.isAuthenticated')) return;
      var namespace = this.get('namespace'); // join "lobby" for my role, mainly for broadcasts

      var lobby = this.joinChannel(namespace + ":all", {
        trackDataUpdates: true
      }); // join channel for my specific user

      var userChannel = this.joinChannel(namespace + ":" + this.get('currentUser.user.id'), {
        trackDataUpdates: true
      });
      this.set('lobby', lobby);
    },
    joinChannel: function joinChannel(name, options) {
      var _this = this;

      if (!this.get('isHealthy')) {
        this.connect();
      }

      var channel = this._super(name, options);

      if (options.trackDataUpdates) {
        channel.on('dataUpdate', function (payload) {
          return _this._handleDataUpdate(payload);
        });
      }

      return channel;
    },
    _handleDataUpdate: function _handleDataUpdate(payload) {
      var store = this.get('store');

      if (payload.action === 'create' || payload.action === 'update') {
        store.findRecord(payload.type, payload.id, {
          reload: true
        });
      } else if (payload.action === 'delete') {
        // checks if record is in the store
        // without an http call. if the record isn't loaded
        // no point in fetching and then unloading
        var record = store.peekRecord(payload.type, payload.id);

        if (record && !record.get('isDeleted')) {
          store.unloadRecord(record);
        }
      }
    }
  });

  _exports.default = _default;
});