define("accio-alert/pods/mobile/schedules/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PX4fMRL9",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"log\",[[24,[\"nativeApp\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"streetSweepingCampaigns\",\"firstObject\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"pdsp-my-schedule\",null,[[\"campaignId\",\"campaignIconSrc\",\"onAddLocation\"],[[24,[\"streetSweepingCampaigns\",\"firstObject\",\"id\"]],\"/assets/images/campaigns/originals/street_sweeping.png\",[28,\"action\",[[23,0,[]],\"onAddLocation\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/mobile/schedules/template.hbs"
    }
  });

  _exports.default = _default;
});