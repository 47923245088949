define("accio-alert/pods/components/onboarding/select-campaigns/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    currentAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    nativeApp: Ember.inject.service(),
    router: Ember.inject.service(),
    allCampaignsSelected: true,
    selectedCampaigns: null,
    accountDisplayName: Ember.computed.oneWay('currentAccount.account.accountDisplayName'),
    campaigns: Ember.computed(function () {
      return this.get('store').query('campaign', {
        with_alerts: true
      });
    }),
    chosenCampaigns: Ember.computed(function () {
      var campaigns = this.get('currentUser.user.subscriptions').map(function (s) {
        return s.get('notification.campaign');
      });
      return campaigns;
    }),
    subscribedCampaigns: Ember.computed('currentUser.user.subscriptions.@each', function () {
      var campaignIds = [];
      var campaigns = [];
      return _emberData.default.PromiseArray.create({
        promise: this.get('currentUser.user.subscriptions').then(function (subs) {
          subs.forEach(function (sub) {
            if (campaignIds.indexOf(sub.get('campaign.id')) === -1 && sub.get('enabled')) {
              campaignIds.push(sub.get('campaign.id'));
              campaigns.push(sub.get('campaign'));
            }
          });
          return campaigns;
        })
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedCampaigns', Ember.A());
    },
    actions: {
      campaignGroupChanged: function campaignGroupChanged(val) {
        this.set('allCampaignsSelected', val);
      },
      changeCampaignSelection: function changeCampaignSelection(campaign, newValue) {
        if (newValue) {
          this.selectedCampaigns.pushObject(campaign);
        } else {
          this.selectedCampaigns.removeObject(campaign);
        }
      },
      finish: function finish() {
        if (this.nativeApp.isNativeApp) {
          this.nativeApp.postMessage({
            action: 'login',
            token: this.session.data.authenticated.token
          });
        } else {
          this.router.transitionTo(this.currentUser.user.homeRoute);
        }
      },
      submit: function submit() {
        var _this = this;

        this.saveCampaigns().then(this.completeTask.bind(this)).then(this.reloadCurrentUser.bind(this)).then(function () {
          return _this.get('currentUser.user').hasMany('onboardingTasks').reload();
        });
      }
    },
    saveCampaigns: function saveCampaigns() {
      var url = this.get('ajax.namespacedUrl') + "/subscriptions/batch-add-campaigns";
      var campaignIds = [];

      if (this.get('allCampaignsSelected')) {
        campaignIds = this.get('campaigns').map(function (c) {
          return c.get('id');
        });
      } else {
        campaignIds = this.get('selectedCampaigns').map(function (c) {
          return c.get('id');
        });

        if (campaignIds.length === 0) {
          alert('Please choose at least one campaign');
          return;
        }
      }

      return this.get('ajax').post(url, {
        data: {
          campaignIds: campaignIds
        }
      });
    },
    reloadCurrentUser: function reloadCurrentUser() {
      return this.get('currentUser').load();
    },
    completeTask: function completeTask() {
      var model = this.get('model');
      model.set('status', 'completed');
      return model.save();
    }
  });

  _exports.default = _default;
});