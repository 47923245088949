define("accio-alert/pods/main/locations/edit/finish/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gb8kdw0D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"col-lg-12 text-center\"],[8],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"m-t-0 m-b-0\"],[8],[7,\"strong\",true],[10,\"class\",\"text-custom\"],[8],[0,\"Success\"],[9],[9],[0,\"\\n\\t\\t\"],[7,\"h3\",true],[10,\"class\",\"m-t-0 m-b-0\"],[8],[1,[24,[\"model\",\"firstObject\",\"parentLocation\",\"nickname\"]],false],[0,\" Added\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\nHere’s what to expect:\\n\"],[7,\"ol\",true],[8],[0,\"\\n\\t\"],[7,\"li\",true],[8],[0,\"Access the service schedule for this address at myDPW anytime.\"],[9],[0,\"\\n\\t\"],[7,\"li\",true],[8],[0,\"You’ll receive reminders for your active subscriptions.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"p\",true],[8],[0,\"Need additional support?  Contact \"],[7,\"a\",true],[11,\"href\",[29,[\"mailto:\",[24,[\"currentAccount\",\"account\",\"accountSupportEmail\"]]]]],[8],[1,[24,[\"currentAccount\",\"account\",\"accountSupportEmail\"]],false],[9],[0,\" or call \"],[7,\"a\",true],[11,\"href\",[29,[\"tel:\",[24,[\"currentAccount\",\"account\",\"accountSupportPhone\"]]]]],[8],[1,[24,[\"currentAccount\",\"account\",\"accountSupportPhone\"]],false],[9],[0,\".\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"text-center m-b-20 m-t-20\"],[8],[0,\"\\n\\t\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"btn btn-lg btn-default\",\"main.locations.new.home\"]],{\"statements\":[[7,\"i\",true],[10,\"class\",\"glyphicons glyphicons-plus\"],[8],[9],[0,\" Add Another Location\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"a\",false],[12,\"class\",\"btn btn-lg btn-default\"],[3,\"action\",[[23,0,[]],\"finish\"]],[8],[0,\"I'm Finished\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "accio-alert/pods/main/locations/edit/finish/template.hbs"
    }
  });

  _exports.default = _default;
});